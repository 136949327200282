
// export const SERVER_URL = "http://localhost:8000/api/";
// export const SERVER_MEDIA = "http://localhost:8000/media/";
export const SERVER_VIDEO = "http://127.0.0.1:8000/media/videos/";



export const SERVER_URL = "https://api.vealaweb.com/api/";
export const SERVER_MEDIA = "https://api.vealaweb.com/media/";
// export const SERVER_VIDEO = "http://127.0.0.1:8000/media/videos/";


